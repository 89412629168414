/// <reference path="../../typings/index.d.ts" />

$(function() {

    $('.animation input').change(function() {
        if(!$(this).hasClass('filled')) {
            $(this).addClass('filled');
        } else {            
            $(this).removeClass('filled');
        }
    });

    $('.animation textarea').change(function() {
        if(!$(this).hasClass('filled')) {
            $(this).addClass('filled');
        } else {            
            $(this).removeClass('filled');
        }
    });

    if ($(window).width() >= 768) {

        $('section.price .title').eq(0).addClass('active');

        let price_height = Math.max($('section.price .title.active + .content').outerHeight() + parseInt($('section.price .title.active + .content').css('top').replace(/\D+/g,"")) + 100, 630);
        $('section.price > .wrapper').css('minHeight', price_height+'px');
    }

    $(window).resize(function() {
        if ($(window).width() >= 768) {            
            
            if($('section.price .title.active').length == 0) {
                $('section.price .title').eq(0).addClass('active');
            }

            let price_height = Math.max($('section.price .title.active + .content').outerHeight() + parseInt($('section.price .title.active + .content').css('top').replace(/\D+/g,"")) + 100, 630);
            $('section.price > .wrapper').css('minHeight', price_height+'px');
        }
    });

    $('section.price .title').click(async function(e) {
        e.preventDefault();

        $(this).siblings('.title').each(function( index ) {
            if($(this).hasClass('active')) {
                $(this).removeClass('active');
            } 
        });
        
        if(!$(this).hasClass('active')) {
            $(this).addClass('active');
        } else {  
            if ($(window).width() < 768){          
                $(this).removeClass('active');        
            }    
        }

        if ($(window).width() >= 768) {
        let price_height = Math.max($('section.price .title.active + .content').outerHeight() + parseInt($('section.price .title.active + .content').css('top').replace(/\D+/g,"")) + 100, 630);
            $('section.price > .wrapper').css('minHeight', price_height+'px');
        }
    });

    //скролл
    if($('.scroll').length) {
        $('.scroll').click(function(e) {
            e.preventDefault();
            let scroll_el = $(this).attr('href'); 
            if ($(scroll_el).length != 0) { 
                if ($(window).width() >= 1280) {
                    $('html, body').animate({ scrollTop: $(scroll_el).offset().top }, 500); 
                } else {
                    $('html, body').animate({ scrollTop: $(scroll_el).offset().top -40}, 500);                     
                }
            }
        });
    }

    // setTimeout(function() {
    //     $('select').styler({
    //         onSelectOpened: function() {
    //             let a = $(this);
    //             setTimeout(function() { a.addClass('opened2'); }, 10);
    //         },
    //         onSelectClosed: function() {
    //             let a = $(this);
    //             setTimeout(function() { a.removeClass('opened2'); }, 10);
    //         }
    //     });
    // }, 100);

    $('input[name="phone"]').focus(function() {
        if($(this).val() == "") {
            $(this).val('+7 (');
        }   
      });

      $('input[name="phone"]').focusout(function() {
          $(this).mask("+7 (000) 000-00-00", {
            clearIfNotMatch: true
          });
      });

    $('input[name="phone"]').bind("change keyup input", function() { 

        var num = $(this).val().replace(/\D+/g,""); 
        
        if(num.indexOf("789") === 0 || num.indexOf("77") === 0 || num.indexOf("89") === 0 || num === "" || num === "7") {

            if(num.indexOf("789") === 0 || num.indexOf("77") === 0) {    
                num = num.substr(2 - num.length, num.length - 2);
            }

            if(num.indexOf("89") === 0) { 
                num = num.substr(1 - num.length, num.length - 1);
            }

            if(num === "7") {
                num = "";
            }

            num = "+7 (" + num;
            $(this).val(num);
            $(this).mask("+7 (000) 000-00-00", {
                clearIfNotMatch: true
              });
            var b = $(this).val();
            $(this).val(b);
        }


      });
      
      $('input[name="phone"]').mask("+7 (000) 000-00-00", {
          clearIfNotMatch: true
      });

      $('section.about .nav a').click(function(e) {
          e.preventDefault();

          if(!$(this).hasClass('active')) {
              $(this).siblings('.active').removeClass('active');
              $(this).addClass('active');
          }

          if(!$($(this).attr('href')).hasClass('active')) {
              $($(this).attr('href')).siblings('.active').removeClass('active');
              $($(this).attr('href')).addClass('active');
          }


      });

      $('.popup_show').click(function(e) {
          e.preventDefault();

          $($(this).attr('href')).addClass('show');

          $('.popup_background').addClass('show');
      });

      $('.popup .close').click(function(e) {
          e.preventDefault();

          closePopups();
      });

      $('.popup_background').click(function(e) {
          e.preventDefault();

          closePopups();
      });

      $('.burger').click(function(e) {
         e.preventDefault();
         if ($(window).width() < 1280) {
            toggleMenu();
         }
      });

      $('#menu ul a').click(function(e) {
         e.preventDefault();
         if ($(window).width() < 1280) {
            toggleMenu();
         }         
      });

      $('#menu .up').click(function(e) {
        e.preventDefault();
        if ($(window).width() < 1280) {
           toggleMenu();
        }         
     });
      
});

function closePopups() {
    $('.popup_background').removeClass('show');    
    $('.popup').removeClass('show');  
}

function showSuccess() {
    closePopups();

    $('#success').addClass('show');

    $('.popup_background').addClass('show')

}

function toggleMenu() {
    let menu = $('#menu');

    if(!menu.hasClass('opened')) {
        menu.addClass('opened');
        $(document).mouseup(function (e){ 
            if (!menu.is(e.target) // если клик был не по нашему блоку
                && menu.has(e.target).length === 0) { // и не по его дочерним элементам
                menu.removeClass('opened');
            }
        });
    } else {
        menu.removeClass('opened');
    }
}


